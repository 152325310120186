import React, {
    PropsWithChildren,
    ReactElement,
} from "react";
import styles from "./StaffGrid.module.scss";

interface StaffGridProps {
    personal_trainers: any;
    grid_title: any;
    instructors: any;
    setStaff: (staff: any) => void;

}

const StaffGrid = (props: PropsWithChildren<StaffGridProps>): ReactElement => {

    const { personal_trainers, setStaff, instructors } = props;

    const location = {};

    if (personal_trainers) {
        personal_trainers.forEach((item: object) => {
            const { locations } = item.dataRaw;

            for (let loc of locations) {
                if (!location[loc.location.slug]) {
                    location[loc.location.slug] = [];
                }

                location[loc.location.slug].push(item);
            }
        });
    }

    let staff;
    if (personal_trainers) {
        staff = Object.keys(location).map((key, i: number) => (
            <div className={styles.staffGrid} key={i}>
                <h2>
                    {key.replace(/-/gi, " ").replace("pbsc", "").trimStart() ||
                        "PBSC"}
                </h2>
                <div className={styles.grid}>
                    {location[key].map((staff: any, i: number) => (
                        <div
                            key={i}
                            className={styles.gridItem}
                            style={{
                                backgroundImage: `url(${staff.dataRaw.image_profile?.url})`,
                            }}
                            onClick={() => setStaff(staff)}
                        >
                            {staff.dataRaw.trainer_name && (
                                <h3 className={styles.nameTrainer}>
                                    {staff.dataRaw.trainer_name[0]?.text}
                                </h3>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        ));
    } else if (instructors) {
        staff = (
            <div className={`${styles.staffGrid} ${styles.isRounded}`}>
                <div className={`${styles.grid} ${styles.rounded}`}>
                    {instructors.map((staff: any, i: number) => (
                        <div key={i}>
                            <div
                                className={`${styles.gridItem} ${styles.rounded}`}
                                style={{
                                    backgroundImage: `url(${staff.dataRaw.profile_img?.url})`,
                                }}
                            ></div>
                            {staff.dataRaw.page_title && (
                                <h3 className={styles.nameInstructor}>
                                    {staff.dataRaw.page_title[0]?.text}
                                </h3>
                            )}
                            {staff.dataRaw.discipline && (
                                <p className={styles.nameDiscipline}>
                                    {staff.dataRaw.discipline[0]?.text}
                                </p>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    return (
        <section>
                <h2 className={`${styles.componentTitle} wideBox`}>The team</h2>
                {staff}
        </section>
    );
};

export default StaffGrid;
