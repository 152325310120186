import React, {
    PropsWithChildren,
    forwardRef,
    ReactElement,
    PointerEvent,
    useEffect,
    MutableRefObject,
} from "react";
import { useEventListener } from "../../../hooks/UseEventListener";
import { closeBtnAlt } from "../../../shared/icons";
import styles from "./PopUp.module.scss";
interface PopUpProps {
    selectedStaff: any;
    setSelectedStaff: (staff: any) => void;
}
const PopUp = forwardRef<HTMLDivElement, PropsWithChildren<PopUpProps>>(
    (props, ref): ReactElement => {
        const handlePointerDown = (e: PointerEvent) => {
            if (
                ref &&
                !(ref as MutableRefObject<HTMLDivElement>).current?.contains(
                    e.target as any
                )
            ) {
                e.stopPropagation()
                props.setSelectedStaff(null);
            }
        };
        const escHandler = (e: any) => {
            if (e.keyCode === 27 && props.selectedStaff != null) {
                props.setSelectedStaff(null);
            }
        };
        useEventListener(
            "keydown",
            escHandler,
            typeof window !== "undefined" ? window : null,
            false
        );
        useEventListener("click", handlePointerDown);
        useEffect(() => {
            document.body.classList.add("no-overflow");
            return () => document.body.classList.remove("no-overflow");
        }, [props.selectedStaff]);

        const withoutRightSide = (props.selectedStaff.dataRaw.story.length <= 0 && props.selectedStaff.dataRaw.specialities.length <= 0 && props.selectedStaff.dataRaw.motivational_quote.length <= 0)
        
        return (
            <div className={styles.popUpWrapper}>
                <div {...{ ref }} className={styles.popUp} >
                    <button
                        onClick={() => props.setSelectedStaff(null)}
                    >
                        {closeBtnAlt}
                    </button>
                    <div
                        className={`${styles.leftSide} ${ withoutRightSide ? styles.withoutRightSide : ''}`}
                        style={{
                            backgroundImage: `url(${props.selectedStaff.dataRaw.image_profile?.url})`,
                        }}
                    >
                        {withoutRightSide
                            && props.selectedStaff.dataRaw.trainer_name &&
                            (
                                <h3 className={styles.trainerName}>
                                    <span>
                                        {
                                            props.selectedStaff.dataRaw
                                                .trainer_name[0]?.text
                                        }
                                    </span>
                                </h3>
                            )}
                    </div>
                    {(props.selectedStaff.dataRaw.story.length > 0 || props.selectedStaff.dataRaw.specialities.length >
                        0 || props.selectedStaff.dataRaw.motivational_quote.length > 0)
                        && <div
                            className={styles.rightSide}
                            onScroll={e => e.stopPropagation()}
                        >
                            <div className={styles.popUpHead}>
                                {props.selectedStaff.dataRaw.trainer_name && (
                                    <h3 className={styles.trainerName}>
                                        <span>
                                            {
                                                props.selectedStaff.dataRaw
                                                    .trainer_name[0]?.text
                                            }
                                        </span>
                                    </h3>
                                )}
                                {props.selectedStaff.dataRaw.motivational_quote
                                    .length > 0 &&
                                    props.selectedStaff.dataRaw.motivational_quote.map(
                                        (x: any, i: number) => (
                                            <p
                                                className={styles.trainerQuote}
                                                key={i}
                                            >
                                                <span>{x.text}</span>
                                            </p>
                                        )
                                    )}
                            </div>
                            <div className={styles.popUpBody}>
                                {props.selectedStaff.dataRaw.story.length > 0 && (
                                    <h4 className={styles.titles}>My story</h4>
                                )}
                                {props.selectedStaff.dataRaw.story.length > 0 &&
                                    props.selectedStaff.dataRaw.story.map(
                                        (x: any, i: number) => (
                                            <p key={i}>{x.text}</p>
                                        )
                                    )}
                                {props.selectedStaff.dataRaw.specialities.length > 0 && (
                                    <h4 className={styles.titles}>specialities</h4>
                                )}
                                {props.selectedStaff.dataRaw.specialities.length >
                                    0 &&
                                    props.selectedStaff.dataRaw.specialities.map(
                                        (x: any, i: number) => (
                                            <p key={i}>{x.text}</p>
                                        )
                                    )}
                            </div>
                        </div>}
                </div>
            </div>
        );
    }
);
export default PopUp;